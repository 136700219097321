export default {
  data () {
    return {
      getAllLanguages: [
        {
          name: 'العربية',
          lang: 'ar',
          is_default: 1,
          is_available: 1
        },
        {
          name: 'English',
          lang: 'en',
          flag: 'en.png',
          is_default: 0,
          is_available: 1
        }
      ]
    }
  }
}
