import getLanguages from '@/mixins/getLanguages'
export default {
  mixins: [getLanguages],
  methods: {
    handleResponseTranselation (keys, dynamicTranselation, langs = this.getAllLanguages) {
      const translations = []
      langs.forEach(lang => {
        var getTrans = dynamicTranselation.find(dynamic => dynamic.lang === lang.lang)
        if (getTrans) {
          translations.push(getTrans)
        } else {
          const obj = keys.reduce((accumulator, value) => {
            return {
              ...accumulator,
              [value]: ''
            }
          }, {})
          translations.push({
            ...obj,
            lang: lang.lang
          })
        }
      })
      return translations
    }
  }
}
